import { makeStyles } from "@mui/styles";


const BASE_COLOR = {
    main: '#8257E5 !important',
}

const COLORS = {
  primary: BASE_COLOR.main,
  primaryHover: '#6F4BD8',
  secondary: BASE_COLOR.main,
  white: '#fff',
};

const SIZES = {
  paddingSmall: '1rem',
  paddingMedium: '2rem',
  paddingLarge: '2rem',
  borderRadius: '0.5rem',
  fontSizeBase: '1rem',
  boxShadow: '0 0 0.9375rem rgba(0, 0, 0, 0.2)',
  maxWidthModal: '25rem',
  smallBoxShadow: '0 -0.125rem 0.3125rem rgba(0, 0, 0, 0.1)',
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: COLORS.white,
    padding: SIZES.paddingMedium,
    borderRadius: SIZES.borderRadius,
    boxShadow: SIZES.boxShadow,
    maxWidth: SIZES.maxWidthModal,
    textAlign: 'center',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
  },
  '@global': {
    '.modalContent::-webkit-scrollbar': {
      width: '0',
      height: '0',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    marginBottom: SIZES.paddingSmall,
  },
  modalActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  modalButton: {
    backgroundColor: COLORS.secondary,
    color: COLORS.white,
    padding: '0.625rem 0',
    width: '100%',
    borderRadius: SIZES.borderRadius,
    textTransform: 'none',
    fontSize: SIZES.fontSizeBase,
    '&:hover': {
      backgroundColor: COLORS.primaryHover,
    },
  },
  cancelButton: {
    marginTop: SIZES.paddingSmall,
    color: COLORS.primary,
    textTransform: 'none',
    fontSize: SIZES.fontSizeBase,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  linkButton: {
    marginTop: 'auto',
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    textTransform: 'none',
    fontSize: SIZES.fontSizeBase,
    borderRadius: SIZES.borderRadius,
    width: '100%',
    padding: SIZES.paddingSmall,
    '&:hover': {
      backgroundColor: COLORS.primaryHover,
    },
  },
  buttonContainer: {
    padding: SIZES.paddingSmall,
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
    boxShadow: SIZES.smallBoxShadow,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '@media (min-height: 32.82rem)': { 
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      margin: '0 auto',
      width: '100%',
    },
  },
});

export default useStyles;
