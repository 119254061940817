import "../css/linkMediator.css";
import { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import authenticateMediatore from "../services/mediatore/authenticateMediatore";

const LinkMediatoreForm = ({ onCloseModal, auth_token }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSucceed, setIsMessageSucceed] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    return usernameRegex.test(username);
  };

  function handleSucceedLogin() {
    resetForm();
    setIsMessageSucceed(true);
    setErrorMessage("");
    setIsLoginSuccess(true);
  }

  function resetForm() {
    setUsername("");
    setPassword("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!validateUsername(username)) {
      setUsernameError("Usuario no puede tener caracteres especiales");
      isValid = false;
    } else {
      setUsernameError("");
    }

    if (password.length === 0) {
      setPasswordError("contraseña es requerida");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (isValid) {
      setIsLoading(true);
      let response = await authenticateMediatore(
        username,
        password,
        auth_token
      );

      let data = await response.json();
      if (data.success) {
        handleSucceedLogin();
      } else {
        setErrorMessage("Credenciales invalidas");
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoginSuccess ? (
        <>
          <p>Intermediario autenticado con éxito.</p>
          <Button style={{ color: "#FFF", textTransform: "none" }} onClick={onCloseModal}>
            Cerrar
          </Button>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isLoading && <CircularProgress color="success" />}
          </div>
          {isMessageSucceed && (
            <Typography
              style={{
                color: "#269202",
                fontFamily: "Figtree",
                marginBottom: 10,
              }}
            >
              Intermediario autenticado con exito
            </Typography>
          )}
          <InputLabel>Usuario</InputLabel>

          <TextField
            type="text"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!usernameError}
            helperText={usernameError}
            required
            sx={{ "& .MuiInputBase-input": { color: "black" } }}
          />

          <InputLabel
            htmlFor="outlined-adornment-password"
            className="pass-label"
          >
            Contraseña
          </InputLabel>
          <TextField
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
            sx={{ "& .MuiInputBase-input": { color: "black" } }}
            required
          />
          <Box mt={2}>
            <Button type="submit" fullWidth className={classes.buttonLink}>
              Autenticar usuario
            </Button>

            {errorMessage && (
              <Typography
                style={{
                  color: "#ff8661",
                  textAlign: "center",
                  padding: 5,
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
        </form>
      )}
    </>
  );
};

const useStyles = makeStyles({
  buttonLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "16px 48px",
    borderRadius: "8px",
    backgroundColor: "#8066ec",
    textTransform: "none",
    flexGrow: 0,
    fontFamily: "Figtree",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#6851c8",
    },
  },
});

export default LinkMediatoreForm;
