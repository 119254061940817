import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  container: {
    overflowY: "auto",
    height: "100%",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  listRoot: {
    padding: "0",
    position: "relative",
    margin: "0px -25px 0px 0px",
    marginBottom: "7rem",
  },
  logo: {
    display: "block",
    marginTop: "-4rem",
  },
})

export default useStyles
